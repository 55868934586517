import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    height:100%
    font-size: 62.5%;
  }

  body {
    --bg: white;
    --textNormal: black;
    --textTitle: black;
    --textLink: black;
    --svg: black;
    --hr: hsla(0, 0%, 0%, 0.2);
    --bgProject: #f0f0f0;
  
    background-color: var(--bg);
    color: var(--textNormal);

    height:100%;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    font-size: 1.6rem;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    transition: 0.3s;
  }
  
  body.dark {
    -webkit-font-smoothing: antialiased;
  
    --bg: #2A2A2A;
    --textNormal: white;
    --textTitle: white;
    --textLink: white;
    --svg: white;
    --hr: hsla(0, 0%, 100%, 0.2);
    --bgProject: #3A3A3A;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: var(--textLink);
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-family: 'Roboto', sans-serif;
  }

  video {
    max-width: 100%;
  }

  .mailIcon {
    stroke: var(--svg);
  }

  .logo {
    color: var(--svg);
    fill: var(--svg);
  }

  p {
    margin-bottom: 2rem;
  }
`;
