import * as React from 'react';

function Logo() {
  return (
    <svg className="logo" width={45} height={54} viewBox="0 0 45 54">
      <path
        d="M29.201 54V10.539H45L34.708 0H19v43.368L29.201 54zM16 10V0H0l10.483 10H16z"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default Logo;
