import styled from 'styled-components';

export const Container = styled.nav`
  padding: 3.2rem 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--hr);
  text-transform: uppercase;
  font-size: 1.4rem;

  a {
    text-decoration: none;
    padding: 14px 34px;
    opacity: 0.8;
  }

  .active {
    border: 1px solid var(--hr);
    border-radius: 1000px;
  }

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
  }
`;
