import styled from 'styled-components';

export const TopNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
`;

export const DarkBtn = styled.div`
  input[type='checkbox'] {
    width: 20px;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid var(--textNormal);
    background-color: var(--bg);
  }

  label:hover:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 10px solid var(--textNormal);
    background-color: var(--bg);
  }
`;
