import React from 'react';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { TopNav, DarkBtn } from './header.css';
import Nav from 'components/header/nav';
import Logo from 'components/logo/logo';
import Mail from 'components/mail/mail';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = () => (
  <AnimatedContainer>
    <div id="header">
      <TopNav>
        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <DarkBtn>
              <label>
                <input
                  type="checkbox"
                  onChange={e =>
                    toggleTheme(e.target.checked ? 'dark' : 'light')
                  }
                  checked={theme === 'dark'}
                />
              </label>
            </DarkBtn>
          )}
        </ThemeToggler>
        <Link to="/">
          <Logo />
        </Link>
        <Mail />
      </TopNav>
      <Nav />
    </div>
  </AnimatedContainer>
);

export default Header;
